<template>
  <button :type="type" :disabled="disabled" :class="[btnClass, 'transition-all', { 'opacity-0': isLoading }]">
    <slot/>
    <transition name="fade-fast">
      <div
        v-if="isLoading"
        class="transition-all absolute flex justify-center align-middle left-0 top-0 w-full h-full pointer-events-none overflow-hidden"
        role="status"
      >
        <img
          class="inline-block spinner-border animate-spin-slowing"
          :src="spinnerSrc"
          width="20"
          height="20"
        >
      </div>
    </transition>
  </button>
</template>

<script setup>
import spinnerWhite from '@/assets/img/spinners/spinner-white.svg';
import spinnerBlack from '@/assets/img/spinners/spinner-black.svg';
import { useButtonStyle } from '~/composables/useButtonStyle';

const props = defineProps({
  type: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  padding: {
    type: Boolean,
    default: true,
  },
  hasDisabledState: {
    type: Boolean,
    default: true,
  },
  disabled: Boolean,
  isLoading: Boolean,
});

const btnClass = computed(() => {
  const { btnClass, } = useButtonStyle(
    props.theme,
    props.size,
    null,
    props.padding,
    props.hasDisabledState
  );
  return btnClass;
});
const spinnerSrc = computed(() => ['dark', 'gold',].includes(props.theme) ? spinnerBlack : spinnerWhite);
</script>
