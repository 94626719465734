import { defineStore } from 'pinia';

export const useLocationStore = defineStore({
  id: 'location-store',
  state: () => {
    return {
      initialised: false,
      restricted: false,
      country: '',
      region: '',
      restrictedFeatures: [],
    };
  },
  getters: {
    allRestricted() {
      return this.initialised ? this.restricted && !this.restrictedFeatures?.length : true;
    },
    isGamingRestricted() {
      return this.allRestricted || this.initialised ? this.restrictedFeatures.includes('Gaming') : false;
    },
    isDepositRestricted() {
      return this.allRestricted || this.initialised ? this.restricted && this.restrictedFeatures.includes('Deposit') : true;
    },
    isWithdrawalRestricted() {
      return this.allRestricted || this.initialised ? this.restricted && this.restrictedFeatures.includes('Withdrawal') : true;
    },
    isSignUpRestricted() {
      return this.allRestricted || this.initialised ? this.restricted && this.restrictedFeatures.includes('SignUp') : true;
    },
    isSportsRestricted() {
      return this.allRestricted || this.initialised ? this.restrictedFeatures.includes('Sports') : false;
    },
  },
  actions: {
    async init() {
      const nuxtApp = useNuxtApp();

      try {
        const res = await nuxtApp.$api('/auth/access', { method: 'GET', });
        this.restricted = res.restricted;
        this.country = res.country;
        this.region = res.region;

        if (res.features && res.features.length > 0) { this.restrictedFeatures = res.features; }
      } catch (e) {
        nuxtApp.$rollbar.error(`Access check failed: ${e}`);
      } finally {
        this.initialised = true;
      }
    },
  },
});
