import { defineStore } from 'pinia';

import { useAuthStore } from './auth';

export const useCryptoStore = defineStore({
  id: 'crypto-store',
  state: () => {
    return {
      balance: '0',
      exchangeRateEthUsd: null,
      exchangeRateBtcUsd: null,
      exchangeRateSolUsd: null,
      exchangeRateUsdtUsd: null,
      exchangeRateUsdcUsd: null,
      exchangeRateDaiUsd: null,
      exchangeRateLtcUsd: null,
      exchangeRateDogeUsd: null,
      exchangeRateBchUsd: null,
      exchangeRateXrpUsd: null,
      exchangeRateEosUsd: null,
      exchangeRateTrxUsd: null,
      exchangeRateBnbUsd: null,
      exchangeRateMaticUsd: null,
      exchangeRateShflUsd: null,
      exchangeRateEurc: null,
      exchangeRateRlbUsd: null,
      exchangeRateJckptUsd: null,
      exchangeRateWifUsd: null,
      exchangeRates: null,
      balanceTimerSub: null,
      initiated: false,
      rates: null,
    };
  },
  actions: {
    cryptoInit() {
      this.initiated = true;

      this.walletGet();
      this.getExchangeRate();
      if (!this.balanceTimerSub) {
        this.balanceTimerSub = setInterval(() => {
          this.walletGet();
          this.getExchangeRate();
        }, 30000);
      }
    },
    async walletGet() {
      const { wallet, address, } = useAuthStore();

      if (!wallet || !address) { return; }

      try {
        this.balance = await wallet.getBalance(address);
      } catch (err) {
        useNuxtApp().$rollbar.error('walletGet Failed:', err);
      }
    },
    startTransaction(dataOrAmount, network) {
      const { wallet, } = useAuthStore();

      if (!wallet) { return; }

      return wallet.startTransaction(dataOrAmount, network);
    },
    async getExchangeRate() {
      const nuxtApp = useNuxtApp();
      try {
        const rates = await nuxtApp.$api('/banking/crypto/price', { method: 'GET', });
        this.rates = rates;
        this.exchangeRateEthUsd = Number(this.rates?.ETH?.USD) || null;
        this.exchangeRateBtcUsd = Number(this.rates?.BTC?.USD) || null;
        this.exchangeRateSolUsd = Number(this.rates?.SOL?.USD) || null;
        this.exchangeRateUsdtUsd = Number(this.rates?.USDT?.USD) || null;
        this.exchangeRateUsdcUsd = Number(this.rates?.USDC?.USD) || null;
        this.exchangeRateDaiUsd = Number(this.rates?.DAI?.USD) || null;
        this.exchangeRateLtcUsd = Number(this.rates?.LTC?.USD) || null;
        this.exchangeRateDogeUsd = Number(this.rates?.DOGE?.USD) || null;
        this.exchangeRateBchUsd = Number(this.rates?.BCH?.USD) || null;
        this.exchangeRateXrpUsd = Number(this.rates?.XRP?.USD) || null;
        this.exchangeRateEosUsd = Number(this.rates?.EOS?.USD) || null;
        this.exchangeRateTrxUsd = Number(this.rates?.TRX?.USD) || null;
        this.exchangeRateBnbUsd = Number(this.rates?.BNB?.USD) || null;
        this.exchangeRateMaticUsd = Number(this.rates?.MATIC?.USD) || null;
        this.exchangeRateShflUsd = Number(this.rates?.SHFL?.USD) || null;
        this.exchangeRateRlbUsd = Number(this.rates?.RLB?.USD) || null;
        this.exchangeRateJckptUsd = Number(this.rates?.JCKPT?.USD) || null;
        this.exchangeRateWifUsd = Number(this.rates?.WIF?.USD) || null;
        this.exchangeRateBnk1kUsd = Number(this.rates?.BNK1K?.USD) || null;
        this.exchangeRateEurc = Number(this.rates?.EURC?.USD) || null;
      } catch (err) {
        // nuxtApp.$rollbar.error('method failed', err);
        console.error(err);
      }
    },
    async getTokenBalance(tokenAddress) {
      const { wallet, address, } = useAuthStore();

      if (!wallet || !address) { return; }

      try {
        return await wallet.getTokenBalance(tokenAddress);
      } catch (err) {
        console.log(err);
        useNuxtApp().$rollbar.error('getTokenBalance Failed:', err);
      }
    },
    async depositTokenAmount(tokenAddress, amount, network) {
      const { wallet, address, } = useAuthStore();

      if (!wallet || !address) { return; }

      await wallet.checkChain(network);
      try {
        return await wallet.depositTokenAmount(tokenAddress, amount, network);
      } catch (err) {
        console.log(err);
        useNuxtApp().$rollbar.error('depositTokenAmount Failed:', err);
        throw err;
      }
    },
  },
});
