import { defineStore } from 'pinia';

const REFERRAL_ROOT_PATHS = ['referral', 't',];

export const useReferralStore = defineStore({
  id: 'referral-store',
  state: () => {
    return {
      referralUser: null,
    };
  },
  actions: {
    async init(to) {
      const currentPath = to.path.split('/').filter(Boolean);

      // FIX: This is just a hotfix for hiding the welcome modal and needs to be fixed asap - @Subwaytime - Dez 4 2023
      // Link to the Ticket: https://metawin.atlassian.net/jira/software/c/projects/MET/issues/MET-638
      if (to.query.hidePopup) {
        localStorage.setItem('welcomeModalHide3', true);
      }

      if (currentPath.length === 2 && REFERRAL_ROOT_PATHS.includes(currentPath[0])) {
        const data = await this.fetchReferrerBySlug(currentPath[1], true);
        this.setTrackingCookie(data, to);
      } else if (to.query && to.query.a) {
        const data = await this.fetchReferrerByDisplayName(to.query.a, to.query.t || '', true);
        this.setTrackingCookie(data, to);
      } else if (to.meta.checkRaf && to.params.id) {
        const data = await this.fetchReferrerByDisplayName(to.params.id, to.query.t || '', true);
        this.setTrackingCookie(data, to, false);
      }
    },
    async fetchReferrerBySlug(slug, click) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/referral/public/slug/${slug}?clicked=${!!click}`, { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async fetchReferrerByDisplayName(value, source, click) {
      const nuxtApp = useNuxtApp();
      const query = {};

      if (source) {
        query.source = source;
      }

      if (click) {
        query.clicked = true;
      }

      try {
        const data = await nuxtApp.$api(`/referral/public/user/${value}`, { method: 'GET', query, });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async fetchReferralStatistics() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/statistics', { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async fetchReferralTrackerStatistics(dateFrom, dateTo, skip = 0, take = 50) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/statistics/tracker', {
          method: 'GET',
          query: {
            dateFrom,
            dateTo,
            skip,
            take,
          },
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getAdminReferralTrackers() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/statistics/admin/trackers', { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    fetchAdminReferralTrackersBySlug(slug) {
      return useNuxtApp().$api(`/referral/statistics/admin/trackers/${slug}`, { method: 'GET', });
    },
    async fetchReferralUser() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/user', { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getReferrals(page) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral', {
          method: 'GET',
          params: {
            page,
            pageSize: 40,
          },
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getRewards(page = 1, pageSize = 20) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/referral/reward?page=${page}&pageSize=${pageSize}`, { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    getRedirect(data, to) {
      const compId = to.query.c || to.query.competition;

      if (compId) { return `/competition/${compId}`; }

      const redirection = to.query.r;
      if (redirection) { return `/${redirection}`; }

      if (data?.redirect) {
        const redirectParts = data.redirect.split(':');

        switch (redirectParts[0]) {
          case 'sweepstake':
            if (redirectParts.length > 1) { return `/competition/${redirectParts[1]}`; }
        }
      }

      return '/';
    },
    /**
     * NB: Not currently used
     */
    getLegacyReferralLink(tracker) {
      return `${window.location.origin}/t/${tracker.slug}`;
    },
    getReferralLink(displayName, source, isRafLink = true) {
      let link = `${window.location.origin}/${displayName.toLowerCase()}/`;

      if (source || !isRafLink) {
        link = `${window.location.origin}?a=${displayName.toLowerCase()}`;
        link += source ? `&t=${source}` : '';
      }

      return link;
    },
    setTrackingCookie(tracker, route, redirect = true) {
      const referralCookie = useCookie('referredCode', { maxAge: 60 * 60 * 24 * 30, });
      referralCookie.value = tracker?.code;
      if (redirect) {
        window.location.href = this.getRedirect(tracker, route);
      }
    },
  },
});
