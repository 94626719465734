export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('moonpay', {
    init(params) {
      if (nuxtApp.$config.public.MOONPAY_ENABLED) {
        return window.MoonPayWebSdk.init({
          flow: 'buy',
          environment: nuxtApp.$config.public.MOONPAY_ENV,
          variant: 'overlay',
          params: {
            apiKey: nuxtApp.$config.public.MOONPAY_KEY,
            ...params,
          },
          debug: true,
        });
      } else {
        return null;
      }
    },
  });
});
