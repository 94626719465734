import { Amplify, Auth } from 'aws-amplify';

export default defineNuxtPlugin((nuxtApp) => {
  const AWS_CONFIG = {
    Auth: {
      region: nuxtApp.$config.public.AWS_REGION,
      userPoolId: nuxtApp.$config.public.USER_POOL_ID,
      userPoolWebClientId: nuxtApp.$config.public.USER_POOL_CLIENT_ID_FEDERATED,
    },
    oauth: {
      domain: nuxtApp.$config.public.FEDERATED_IDENTITY_DOMAIN,
      redirectSignIn: nuxtApp.$config.public.FEDERATED_IDENTITY_LOGIN_URL,
      redirectSignOut: nuxtApp.$config.public.FEDERATED_IDENTITY_LOGOUT_URL,
      responseType: 'code',
    },
  };

  Amplify.configure(AWS_CONFIG);

  return {
    provide: {
      auth: Auth,
    },
  };
});
